import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    backgroundColor: '#333',
    flex: 1,
    margin: '1em',
      lineHeight: 2,
 [theme.breakpoints.down('sm')]: {
     flex: 'none', width: '80%', marginLeft: 'auto', marginRight: 'auto',  
    },
  },
  sec: {
    textAlign: 'left',
    padding: '5%',
    },
}));



export default function WhatCard() {
  const classes = useStyles();
  return (
      <Paper className={classes.root}>
      <div className="PaperBox">
        <Typography variant="h5" component="h3" color="secondary">
        WHAT IS IT? <br />
        </Typography>
        <Typography component="p" color="primary" className={classes.sec}>
        Adid is an app to ex­change your con­tact de­tails through a QR code.<br />
        After en­ter­ing your de­tails, an Adid con­nect (QR) code is cre­ated which is ready to be scanned by any iPhone nat­ive´s / nor­mal cam­era. <br />
        </Typography>
      </div>
      </Paper>
  );
}


{/*

        ● Con­nect to any people's plat­form ac­count at once! 📲<br />

        ● Go for the in­nov­at­ive busi­ness card with Adid!<br />

        ● 🌐 Net­work­ing made simple! <br />

        ● Share your con­tact de­tails with one tap at the tip of your fin­gers.<br />

        ● Nev­er type in a wrong num­ber. 📵<br />

        ● Eas­ily cre­ate your first digi­tal con­tact card.<br />

        ● Nev­er buy busi­ness cards again - Re­tain the en­vir­on­ment.🌳🌱<br />

        ● Busi­ness of the fu­ture with the busi­ness card of the fu­ture!<br />
*/}
