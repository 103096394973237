import React from 'react';
import logo from './logo.svg';
import './App.css';
import NavBar from './NavBar.js';
import Divider from '@material-ui/core/Divider';
import PaperCard from './PaperCard.js';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
        main: '#ccc',
    },
    secondary: {
        main: '#fff',
    },
    background: {
        main: '#212121',  
    },
    secondaryBackground: {
        main: '#17171d',
      },
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
    <div className="App">
    <NavBar />

      <div className="container">
      <h2>Sharing contact details made simple! 👨🏻‍🚀</h2>
      {/*<Divider style={{backgroundColor:'rgba(51,51,51,0.95)', height: '1px'}} />*/}
      <h1>More Convenient. <br /> High-Speed. <br /> Super-Secure.</h1>
      <Divider style={{backgroundColor:'rgba(51,51,51,0.95)', height: '2px'}} className="middle-divider" variant="middle"/>
      <img className="mockup-hero" src={require('./mockup3-adid.png')} />
      {/*
      <div style={{height: '20vh', width: '100%'}}> </div>
            <img className="mockup" src={require('./mockup1.png')} />
      <div style={{height: '20vh', width: '100%'}}> </div>
      <img className="mockup" src={require('./mockup2-adid.png')} />
      
      <h5>
        With Adid, it takes less than a few seconds to ex­change con­tact de­tails di­git­ally, this time in­clud­ing so­cial ac­counts.
      </h5>

      <h3 className="textHeader">
        HOW TO USE IT?<br />
        -----------------
      </h3>
      <p className="textP">
        The per­son who scans the code will re­ceive a notif­ication on their screen, prompt­ing them to add a con­tact.<br /> After click­ing on it, the con­tact app opens and provides all de­tails which just have to be be saved by a click on the “done” but­ton.</p>
      */}
<PaperCard no={ 1 }/>
    <PaperCard no={ 2 }/>
            <img className="mockup" src={require('./mockup1.png')} />
      </div>
  </div>
    </MuiThemeProvider>
  );
}

export default App;
