import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    backgroundColor: '#333',
    flex: 1,
    margin: '1em',
      lineHeight: 2,
 [theme.breakpoints.down('sm')]: {
     flex: 'none', width: '80%', marginLeft: 'auto', marginRight: 'auto',  
    },
  },
  sec: {
    textAlign: 'left',
    padding: '5%',
    },
}));

export default function FeaturesCard() {
  const classes = useStyles();
  return (
      <Paper className={classes.root}>
      <div className="PaperBox">
        <Typography variant="h5" component="h3" color="secondary">
      LINK ALL ACCOUNTS IN THE CONTACTS APP 👤
        </Typography>
        <Typography component="p" color="primary" className={classes.sec}>
        So­cial ac­counts are linked, so that when tap­ping on it, like you
would tap the phone num­ber to call someone, you are get­ting re­dir­ec­ted to
that per­son's re­spect­ive so­cial ac­count, like your in­s­tagram pro­file in
    the in­s­tagram app.<br /> For oth­er so­cial ser­vices like snapchat, when
tap­ping, you are be­ing promp­ted to add them to your friend list.
        </Typography>
      </div>
      </Paper>
  );
}
